/** @format */

export const Questions = {
	lodging: [
		{
			question: 'Can I stay in one of the cabins at the venue?',
			answer:
				'Absolutely! Our preference is that guests stay at the venue in a cabin.',
		},
		{
			question: 'Do I need to bring my own bedding?',
			answer:
				'Yes! Unfortunately the beds in the cabins do not include bedding. Please bring a sleeping bag/pillow and whatever else you need to make your stay comfortable.',
		},
		{
			question: 'How do I reserve my spot in a cabin, and how much is it?',
			answer:
				'We are asking $30 per bed reserved for the entire weekend (Friday 3:00PM - Sunday 10:00AM). There are also RV spots available for those who would like to park an RV which is just $30 for the spot. Unfortunately no tenting is allowed on the property.',
		},
		{
			question: 'Are cabins co-ed?',
			answer:
				'Yes all cabins are co-ed. We also have three cabins which are reserved for families.',
		},
		{
			question: 'How do I pay for my reservation?',
			answer:
				'You can pay for your reservation either by venmo: miwhag, or you can pay through our registry site. Check the registry tab for more.',
		},
	],
	rsvp: [
		{
			question: 'What should I do if I can’t make it?',
			answer: `If you can't make the event, just specify in your RSVP. If you previously RSVP'ed yes, and need to change it to a no, you can do it through the RSVP flow like you did originally.`,
		},
		{
			question: 'When is the RSVP deadline?',
			answer: 'The RSVP deadline is May 21st 2023',
		},
		{
			question: ' Do I need to mail back my RSVP or can I do it online?',
			answer:
				'You can RSVP directly on this site. Just click the RSVP button in the top right corner of the navigation bar.',
		},
	],
	general: [
		{
			question: 'What is the name and address of the venue?',
			answer:
				'The venue is Deer Creek Mountain Camp at 228 S Pine Dr, Bailey, CO 80421. Please type in the address carefully and follow Google Map directions to the venue. You can find a direct link for directions on the Travel page.',
		},
		{
			question: 'What airport should I fly into?',
			answer:
				'Denver International Airport is the closest major airport. It is roughly 1 hour and 20 minutes from the venue.',
		},
		{
			question: 'What time should I arrive?',
			answer: `You can arrive at the venue as early as 3:00pm on Friday. There is also plenty to see and do in Bailey if you fly in early. However, we can't have guests at the venue until 3pm. Just know we & the wedding party will likely be busy setting up during the day before dinner.`,
		},
		{
			question: 'Where should guests park? Is parking free?',
			answer:
				'Parking is free, and there is a large lot on the venue property for guests to use. Some cabins additional parking attached, however these are for family members or those who are hard of walking. See venue map on Lodging Page for parking location.',
		},
		{
			question: 'Can I bring my children?',
			answer:
				'Yes! With a caveat. We understand that leaving the kids at home for a whole weekend might not be possible, and while we would love to see them during the weekend, we are choosing not to have young children (under 8 years old and outside of immediate family) present during the ceremony and dinner.',
		},
		{
			question: 'What are my options if I would like to bring my child(ren)?',
			answer: `Since there won't be any children (outside of family), allowed at the ceremony & dinner, either a parent will need to step out during these times, or we can help set up a sitter service during these hours. The cost of the sitters will need to be handled by the parents however.`,
		},
		{
			question: 'Can I bring a plus one?',
			answer:
				'Unfortunately we do have limitations to the amount of guests we can accommodate. So, unless your RSVP specifies that you have a plus one, we cannot allow extra guests. If you think there is a mistake to your RSVP, please let us know.',
		},

		{
			question: 'Will transportation be provided between to/from the airport?',
			answer:
				'We are unable to offer transportation to and from the airport. However, we can aid in coordination for carpooling.',
		},
		{
			question: 'How do I get to the venue?',
			answer:
				'This venue is only accessible by car. Please search by the actual address in your GPS. There is another venue called Barn at Deer Creek just down the road from our venue, so if you pull up and see a giant white barn you are not at the right venue.',
		},

		{
			question: 'Are dogs allowed?',
			answer:
				'No dogs are allowed on the property. There is a very delicate ecosystem at this venue with tons of deer and small creatures. The owners are strict about no dogs.',
		},
		{
			question: 'Can we have cannabis on the property?',
			answer: 'Sorry, no cannabis smoking allowed on the property.',
		},
		{
			question: 'What is the best way to contact you?',
			answer: 'Please email us at mikemiwha@gmail.com ',
		},
	],
	weekend: [
		{
			question: 'What happens after the ceremony?',
			answer:
				'After the ceremony, the guests will enjoy a cocktail hour at the dining hall until dinner is ready at the main lodge.',
		},
		{
			question: 'Are there other wedding events I can attend?',
			answer:
				'We will be hosting a dodgeball tournament on Friday evening! This will be held in the gym. Those who are not interested in participating in the tournament but would like to watch are more than welcome.',
		},
		{
			question: 'How can I join a dodgeball team?',
			answer:
				'To join a dodgeball team, you simply specify in your RSVP that you would like to participate. You will receive a follow up email once teams are created. The tournament is for ages 8+ and will happen Friday evening after dinner.',
		},
		{
			question: 'What else is there to do around the property?',
			answer:
				'The property is absolutely beautiful. There is a disc golf course (created by the owner), an outdoor volleyball pit, a large area for late night bonfires as well as hiking on-site. There is also plenty of wildlife to enjoy and beautiful spots to relax and read a book or have your morning coffee!',
		},

		{
			question: 'What is there to do nearby?',
			answer:
				'Within 10 minutes of the venue, there are small coffee shops, restaurants, and an amazing winery in town. There are also plenty of hiking trails to enjoy.',
		},
		{
			question: 'What meals will be provided over the weekend?',
			answer: `We will provide dinner on Friday and Saturday as well as Sunday Breakfast. We are also asking for a $5 donation to cover Sunday breakfast. Since we won't have a formal breakfast or lunch on Saturday, we recommend going into town for food since it's only 10 minutes away.`,
		},
		{
			question: `What if I can't easily get to town because I don't have a car?`,
			answer: `We will make sure to have some simple snacks on site so that those who can't get to town easily for Saturday lunch have atleast something to eat. But think PBJ's and things like that.`,
		},
	],
	wedding: [
		{
			question: 'Will food and drinks be served at the reception?',
			answer:
				'Of course! We will have plenty of food and drinks during the reception and cocktail hour prior. We will make sure to have lots of options, however to be safe make sure you fill out the allergen/food sensitivity section of the RSVP if you do have things you cannot eat.',
		},
		{
			question: 'What should I wear? Is there a dress code?',
			answer:
				'The dress code is funky/festive/casual but feel free to dress up as much as you want. Our venue does require some short walking to and from different spaces on somewhat gravel roads, so just keep that in mind.',
		},
		{
			question: 'Where are the ceremony and the reception taking place?',
			answer: `As long as weather permits, the ceremony will be taking place outside next to the dining hall (see map on the lodging page). It's a beautiful spot surrounded by aspens and wildlife. The start of the reception and dinner will be in the main lodge and cocktail hour/dancing will be in the dining hall.`,
		},
		{
			question: 'Will the ceremony and reception be indoors or outdoors?',
			answer: 'Ceremony will be outdoors while the reception will be indoors.',
		},
		{
			question: 'What is the weather like during this time of year?',
			answer: `For accurate weather, make sure you are looking days prior to the date. However during July 2022, the weather was in the 70's with occasional light rain.`,
		},
	],
	other: [
		{
			question: 'Do you have a registry?',
			answer: `We do! It is under the registry tab on our website, however we won't be opening it until June most likely.`,
		},
	],
};
